import React from "react";
import Link from "next/link";
import { staticResource } from "@villa/utils/resources";
import { IOwnProjects } from "@villa/types/mainPage";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Button from "../../Buttons/Button/Button";
import css from "./OfferCard.module.scss";
import { toMoney } from "@villa/utils/toMoney";
import { useAppSelector } from "@/lib/store/app";
import { H2, P3 } from "../../Typography";
import { ArrowIcon } from "@/lib/ui/icons";
import cl from "classnames";

interface IProps {
  ownProject: IOwnProjects;
  index: number;
  length: number;
  theme?: "light" | "dark";
  onClick: () => void;
}

export const OfferCard = ({
  ownProject,
  index,
  length,
  theme = "light",
  onClick,
}: IProps) => {
  const { t } = useTranslation("index");
  const { value, symbol: cur } = useAppSelector((state) => state.settings.currency);

  const money = toMoney(ownProject.price[value]);

  return (
    <div className={cl(css.offerCard, css[`offerCard--${theme}`])} onClick={onClick}>
      <div className={css.offerCardImgWrapper}>
        <Image
          sizes="(max-width: 996px) 405px, (max-width: 1200px) 532px, 33vw"
          src={staticResource(ownProject.image) || "/images/placeholder_image.webp"}
          alt={""}
          fill
        />
      </div>

      <div className={css.descriptionWrapper}>
        <div className={css.descriptionTop}>
          <p className={css.infoText}>
            0{index + 1}/<span className={css.infoText__disabled}>0{length}</span>
            <span className={"ml-8"} />|<span className={"ml-8"} />
            {ownProject.type || "Villa"}
            <span className={"ml-8"} />|<span className={"ml-8"} />
            {ownProject.location || "Phuket"}
          </p>

          <H2 className={css.h2Subtitle}>{ownProject.name}</H2>

          <P3 className={css.article}>{ownProject.description}</P3>
        </div>

        <div className={css.descriptionDown}>
          <H2 className={css.h2Subtitle}>
            {t("prepositions.from") || ""} {cur}
            {money}
          </H2>
          <div className={css.buttonWrapper}>
            <Button
              label={t("learnMoreAbout") || ""}
              theme={theme === "light" ? "light" : "dark-invert"}
              size={"md"}
              as={Link}
              href={ownProject.url}
              icon={<ArrowIcon isRight />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
