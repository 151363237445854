export function getDate(value: string) {
    const dateObject = new Date(value);

    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();

    return `${month}.${year}`;
}

export function getFullDate(value: string) {
    const dateObject = new Date(value);

    const day =  dateObject.getDate().toString();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();

    return `${day}.${month}.${year}`;
}