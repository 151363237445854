import React from "react";
import Link from "next/link";
import cn from "classnames";
import styles from "./Card.module.scss";
import { NextArrowIcon, SvgInstagram, SvgYoutube } from "@/lib/ui/icons";
import { P2 } from "../../Typography";
import { sendSocialClick } from "@/lib/utils/sendGTM";

interface IProps {
  variant: "youTube" | "insta";
  text: string;
  link: string;
}

export const DefaultMediaCard = ({ variant, text, link }: IProps) => {
  const Icon =
    variant === "insta" ? <SvgInstagram isGradient /> : <SvgYoutube isGradient />;

  return (
    <Link target={"_blank"} href={link} className={styles.defaultCard} onClick={sendSocialClick}>
      <div className={styles.defaultCard__inner}>
        {Icon}
        <div className={styles.defaultCard__bottom}>
          <P2 className={cn(variant === "insta" ? styles.defaultCard__textInsta : styles.defaultCard__text)}>{text}</P2>
          <div className={styles.defaultCard__arrow}>
            <NextArrowIcon isGradient  />
          </div>
          
        </div>
      </div>
    </Link>
  );
};

export default DefaultMediaCard;
