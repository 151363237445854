import React from "react";
import Link from "next/link";
import playBtn from "@/public/icons/playBtn.svg";
import { staticResource } from "@villa/utils/resources";
import Image from "next/image";
import styles from "../Card.module.scss";
import cl from "classnames";
import { SvgPlay } from "@/lib/ui/icons";
import { sendSocialClick } from "@/lib/utils/sendGTM";

interface IProps {
  image: any;
  path: string;
}

export const YouTubeCard = ({ image, path }: IProps) => {
  return (
    <Link
      aria-label="youtube video"
      target={"_blank"}
      href={`https://www.youtube.com/watch?v=${path}`}
      onClick={sendSocialClick}
    >
      <div className={cl(styles.card, styles["card--youtube"])}>
        <Image
          src={staticResource(image)}
          alt={""}
          className={"object-fit-cover"}
          sizes="(max-width: 1624px): 392px, (max-width: 996px) 324px, 343px"
          fill
        />
        <SvgPlay className={styles.card__play} />
        <div className={"playIcon-wrapper"}>
          <Image src={playBtn} alt={""} className={"playIcon"} sizes="100px" />
        </div>
      </div>
    </Link>
  );
};

export default YouTubeCard;
