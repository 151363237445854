import { ReactNode } from "react";
import Link from "next/link";
import css from "./TitleTextArrowCard.module.scss";
import { H3, P3 } from "../../Typography";
import { CircleButton } from "../../Buttons";
import cn from "classnames";
import { ArrowIcon } from "@/lib/ui/icons";

interface IProps {
  title: ReactNode;
  text: string;
  href?: string;
  name?: string;
  className?: string;
}

export const TitleTextArrowCard = ({ title, text, href, name, className }: IProps) => {
  if (!href) return (
  <div className={cn(css.card, className)}>
    <H3 className={css.h3TitleText}>{title}</H3>
    <div className={css.buttonContainer}>
      <P3 className={css.text}>{text}</P3>
    </div>
  </div>
  )
  return (
    <Link href={href} className={cn(css.card, className)}>
      <H3 className={css.h3TitleText}>{title}</H3>
      <div className={css.buttonContainer}>
        <P3 className={css.text}>{text}</P3>
        <CircleButton
          as={"button"}
          theme={"mixed"}
          name={name}
          content={<ArrowIcon isRight className={css.linkButton_icon} />}
          className={css.linkButton}
        />
      </div>
    </Link>
  );
};

export default TitleTextArrowCard;
