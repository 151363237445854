import { FC, MouseEvent } from "react";
import styles from "./VillaCard.module.scss";
import { IExtendedOwnVillas } from "../../../../types/mainPage";
import Image from "next/image";
import { H3, P2, P3 } from "../../Typography";
import { Button } from "../../Buttons";
import { useAppSelector } from "../../../../store/app";
import { useTranslation } from "next-i18next";
import { getWordEnding } from "../../../../utils/getWordEnding";
import { FeedBackModal, FeedBackModalNew, modal } from "../../Modal";
import { toMoney } from "../../../../utils/toMoney";

export const VillaCard: FC<IExtendedOwnVillas & {onClick: (e: MouseEvent<HTMLDivElement>) => void, keyPrefix: string}> = ({
  bedrooms,
  district,
  image,
  name,
  price,
  region,
  description,
  secondaryImage,
  priceFrom,
  defaultPrice,
  onClick,
  keyPrefix,
}) => {
  const currency = useAppSelector((state) => state.settings.currency);
  const {
    t,
    i18n: { language },
  } = useTranslation("index");
  const openModal = (value: string) => {
    modal.open(
      <FeedBackModalNew
        leadType="SALE"
        title="seeTheVilla"
        comment={`Запрос просмотра объекта ${value}`}
        eventName={['sale']}
        sourceForm="portfolio villas landing"
      />,
      { isFeedBackNew: true },
    );
  };
  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.card__left}>
        <Image
          className={styles.card__primary}
          src={image}
          loader={({ src }) => src}
          alt="primary image"
          width={585}
          height={297}
        />
        <div className={styles.card__info}>
          <H3>{name}</H3>
          <P3 className={styles.card__description}>
            {t(`${keyPrefix}.` + description)}
          </P3>
          <P2 className={styles.card__district}>
            {t("locations." + region)}, {t("locations." + district)} <span>|</span>{" "}
            {bedrooms}{" "}
            {getWordEnding(
              bedrooms,
              [
                t("declinable.bedroom"),
                t("declinable.bedroomsFew"),
                t("declinable.bedroomsMore"),
              ],
              language,
            )}
          </P2>
        </div>
        <div className={styles.card__divider} />
        <div className={styles.card__price}>
          {price ? (
            <H3>
              {priceFrom ? t("from") : ""} {currency.symbol} {" "}
              {toMoney(price[currency.value])}
            </H3>
          ) : (
            <H3>
              {priceFrom ? t("from") : ""}
              {" ฿ "} {toMoney(defaultPrice)}
            </H3>
          )}
          <Button
            className={styles.card__order}
            as="button"
            size="lg"
            label={t("orderViewing")}
            theme="light-invert"
            onClick={() => openModal(name)}
          />
        </div>
      </div>
      <div className={styles.card__right}>
        <Image
          className={styles.card__secondary}
          src={secondaryImage}
          loader={({ src }) => src}
          alt="secondary image"
          width={364}
          height={558}
        />
      </div>
    </div>
  );
};
