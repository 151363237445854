import { SelectionLayout } from "@/lib/types/selectionPage";
import styles from "./SelectionCard.module.scss";
import Image from "next/image";
import { FC, ReactNode, useCallback } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { staticResource } from "@/lib/utils/resources";
import { H3, P2, P3 } from "../../Typography";
import cn from "classnames";
import { Button, CircleButton } from "../../Buttons";
import { ArrowIcon } from "@/lib/ui/icons";
import { useWindowSize } from "@/lib/hooks/useWindowSize";
import { useAppSelector } from "@/lib/store/app";
import { toMoney } from "@/lib/utils/toMoney";
import { getDate } from "@/lib/utils/getDate";
import { GallerySlider } from "../../Gallery";
import { GalleryTabsModal } from "../../Gallery/GalleryModal";
import { SeparateHeaderModal, modal } from "../../Modal";
import { VideoModal } from "../../VideoModal";
import { PlayOutlinedSvg } from "@/lib/ui/icons/PlayOutlined";
import useEmblaCarousel from "embla-carousel-react";
import { getWordEnding } from "@/lib/utils/getWordEnding";
import { compareDates } from "@/lib/utils/compareDates";

type SelectionProps = {
  layout: SelectionLayout;
};

export const SelectionCard: FC<SelectionProps> = ({ layout }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("index");
  const { isMobile } = useWindowSize(1200);
  const { symbol: cur, value } = useAppSelector((state) => state.settings.currency);
  const money = layout.price && toMoney(layout.price[value]);
  const districtsString = layout.districts.map((item) => t(`locations.${item}`)).join(', ');
  const location = `${t(`locations.${layout.region}`)}, ${districtsString}`;
  const incomeText = layout.projectedIncome
    ? t("projectedIncome")
    : layout.rentalPool && !layout.guaranteedIncome
    ? t("rentalPool") + ":"
    : t("guaranteedIncome");
  const incomeInfo = layout.projectedIncome
    ? `${layout.projectedIncomePercent}% ${t("declinable.projectedPerYear")}`
    : layout.rentalPool && !layout.guaranteedIncome && layout.rentalPoolPercent
    ? `${layout.rentalPoolPercent}/${100 - layout.rentalPoolPercent}%`
    : `${layout.guaranteedIncomePercent}% ${t("declinable.perYear")} ${
        layout.guaranteedIncomePeriod
      } ${
        layout.guaranteedIncomePeriod &&
        t(
          getWordEnding(
            layout.guaranteedIncomePeriod,
            ["declinable.projectedPerYear", "declinable.years", "declinable.yearsFew"],
            language as "ru" | "en",
          ),
        )
      }`;

  const currentDate = new Date().toISOString();
  const constructionState = layout.constructionState.isReady
    ? `${t("readyForMoveIn")}`
    : layout.constructionState.offPlan ? `${t("offPlan")}`
    : (layout.constructionState.constructionDate && compareDates(layout.constructionState.constructionDate, currentDate))
    ? `${t("constructionDate")}: ${getDate(layout.constructionState.constructionDate)}`
    : "";
   
  const { beach, minutes, method } = layout.distanceToBeach;

  const beachText = `${t(`locations.${beach}`)}, ${minutes} ${`${getWordEnding(
    minutes,
    [t("declinable.minute"), t("declinable.minutes"), t("declinable.minutesMore")],
    language as "ru" | "en",
  )} ${method === "FOOT" ? t("byFoot") : t("byCar")}`}`;

  const url = `/${language}/sale/${layout.region === "PHUKET" ? "phuket" : "samui"}/${
    layout.slug
  }?layout=${layout.id}`;

  const views = layout.views;
  if (views.includes("OTHER")) {
    views.splice(views.indexOf("OTHER"), 1);
    views.push("OTHER");
  }

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "center",
  });

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const tabList: string[] = [
    ...(layout.images.length > 0 ? ["photo"] : []),
    ...(layout.videos.length > 0 ? ["video"] : []),
    ...(layout.layoutSchemes.length > 0 ? ["layout"] : []),
  ];

  const onPhotoClick = (tabName: string, index: number) => {
    const initialTabIndex = tabList.findIndex((item) => item === tabName) || 0;
    openModal(index, initialTabIndex);
  };

  const openModal = (initialSlide: number, initialTabIndex: number) => {
    let tabContents: ReactNode[] = [];

    if (layout.images.length > 0) {
      tabContents.push(
        <GallerySlider
          key={layout.images[0]}
          fullSize
          initialSlide={initialSlide}
          items={layout.images.map((item) => staticResource(item))}
        />,
      );
    }

    if (layout.videos.length > 0) {
      tabContents.push(<VideoModal videoId={layout.videos[0]} key={layout.videos[0]} />);
    }

    if (layout.layoutSchemes.length > 0) {
      tabContents.push(
        <GallerySlider
          key={layout.layoutSchemes[0]}
          fullSize
          initialSlide={initialSlide}
          items={layout.layoutSchemes.map((item) => staticResource(item))}
        />,
      );
    }

    return modal.open(
      <GalleryTabsModal
        initialTabIndex={initialTabIndex}
        tabHeaders={tabList.map((tab) => t(tab))}
        tabContents={tabContents}
      />,
      {
        fullScreen: true,
        isGalleryModal: true,
      },
    );
  };

  const onReadAllClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    modal.open(
      <SeparateHeaderModal
        header={t("description")}
        content={
          <>
            <P2>
              {layout.complexName} {t(layout.type)} {layout.name}
            </P2>
            <div className={styles.footer_infoModal}>
              <div className={styles.footer_info_item}>
                <P3>{t("location")}:</P3>
                <P2>{location}</P2>
              </div>
              {beachText && (
                <div className={styles.footer_info_item}>
                  <P3>{t("beach")}:</P3>
                  <P2>{beachText}</P2>
                </div>
              )}

              <div className={styles.footer_info_item}>
                <P3>{incomeText}:</P3>
                <P2>{incomeInfo}</P2>
              </div>
              <div className={styles.footer_info_item}>
                <P3>{t("view")}:</P3>
                <P2>{views.map((item) => t(item)).join(", ")}</P2>
              </div>
              <div className={styles.footer_info_item}>
                <P3>{t("totalArea")}:</P3>
                <P2>
                  {layout.area} {t("m2")}
                </P2>
              </div>
              <div className={styles.footer_info_item}>
                <P3>{t("bedrooms")}:</P3>
                <P2>{layout.bedrooms === 0 ? t("studio") : layout.bedrooms}</P2>
              </div>
            </div>
            <P3>{layout.comment}</P3>
          </>
        }
      />,
      {
        className: styles.popup,
        hideCloseButton: true,
        contentClassName: styles.separateHeaderModal,
      },
    );
  };

  const isAloneImage = layout.images.length > 1 ? false : true;

  return (
    <li className={styles.card}>
      <div className={styles.gallery}>
        <div className={styles.gallery_top}>
          {layout.perfectForInvestment && (
            <P2 className={styles.gallery_top_button}>{t("idealForInvestment")}</P2>
          )}
          {layout.exclusive && (
            <P2 className={styles.gallery_top_button}>{t("exclusive")}</P2>
          )}
        </div>
        {!isAloneImage && (
          <>
           <CircleButton
          name="scroll to next"
          theme={"dark"}
          onClick={scrollNext}
          className={cn(styles.sliderButton, styles.nextButton)}
          as={"button"}
          content={<ArrowIcon isRight />}
          />
          <CircleButton
            name="scroll to previous"
            theme={"dark"}
            onClick={scrollPrev}
            className={cn(styles.sliderButton, styles.prevButton)}
            as={"button"}
            content={<ArrowIcon />}
          />
        </>
        )}
       
        <div className={styles.gallery_wrapper} ref={emblaRef}>
          <div className={styles.gallery_slider}>
            {layout.images.map((image, index) => {
              return (
                <Image
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 70vw"
                  key={image}
                  className={styles.gallery_image}
                  src={staticResource(image) || "/images/placeholder_image.webp"}
                  width={343}
                  height={343}
                  alt=""
                  onClick={() => onPhotoClick("photo", index)}
                />
              );
            })}
          </div>
        </div>

        {layout.videos.length > 0 && (
          <Button
            as={"button"}
            type={"button"}
            label={t("videoReview")}
            theme={"light"}
            size={"md"}
            isIconLeft
            icon={<PlayOutlinedSvg />}
            className={styles.gallery_video}
            onClick={() => onPhotoClick("video", 0)}
          />
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.footer_top}>
          <div>
            <H3 className={styles.cardTitle}>
              {layout.complexName}
            </H3>
            <H3 className={styles.cardTitle}>
              {t(layout.type)} {layout.name}
            </H3>
          </div>
          <P2 className={styles.construction}>{constructionState}</P2>
        </div>
        <div className={styles.footer_info}>
          <div className={styles.footer_block}>
            <div className={styles.footer_info_item}>
              <P3>{t("location")}:</P3>
              <P2>{location}</P2>
            </div>
            {beachText && (
              <div className={styles.footer_info_item}>
                <P3>{t("beach")}:</P3>
                <P2>{beachText}</P2>
              </div>
            )}

            <div className={styles.footer_info_item}>
              <P3>{incomeText}:</P3>
              <P2>{incomeInfo}</P2>
            </div>
          </div>
          <div className={styles.footer_block}>
            <div className={styles.footer_info_item}>
              <P3>{t("view")}:</P3>
              <P2>{layout.views.map((item) => t(item)).join(", ")}</P2>
            </div>
            <div className={styles.footer_info_item}>
              <P3>{t("totalArea")}:</P3>
              <P2>
                {layout.area} {t("prepositions.m2")}
              </P2>
            </div>
            <div className={styles.footer_info_item}>
              <P3>{t("bedrooms")}:</P3>
              <P2>{layout.bedrooms === 0 ? t("studio") : layout.bedrooms}</P2>
            </div>
          </div>
        </div>
        {layout.comment && (
          <div className={styles.commentWrapper}>
            <P3 className={styles.commentText}>{layout.comment}</P3>
            {layout.comment.length >= 155 && (
              <Button
                as={Link}
                href=""
                label={t("readCompletely")}
                theme={"outlined"}
                size={"md"}
                className={styles.commentButton}
                onClick={onReadAllClick}
              />
            )}
          </div>
        )}
        <div className={styles.bottom}>
          {isMobile ? (
            <CircleButton
              as={Link}
              href={url}
              content={<ArrowIcon isRight />}
              theme={"mixed"}
              target="_blank"
              scroll
            />
          ) : (
            <Button
              as={Link}
              href={url}
              target="_blank"
              label={t("moreAbout")}
              theme={"dark"}
              size={"md"}
              scroll
            />
          )}
          {money && cur && (
            <H3 className={styles.price}>
              {cur}
              {money}
            </H3>
          )}
        </div>
      </div>
    </li>
  );
};
