import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const PlayOutlinedSvg: FC<IClassname> = ({className}) => {
  return (
    <svg
    className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M9.3299 7.10541C9.05479 6.96392 8.71679 6.96493 8.44273 7.10807C8.16867 7.25121 8 7.51482 8 7.8V14.2C8 14.4852 8.16867 14.7488 8.44273 14.8919C8.71679 15.0351 9.05479 15.0361 9.3299 14.8946L15.5521 11.6946C15.8291 11.5522 16 11.2871 16 11C16 10.7129 15.8291 10.4478 15.5521 10.3054L9.3299 7.10541Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </svg>
  );
};
