import { SelectionLayout } from "@/lib/types/selectionPage";
import styles from "./SelectionCard.module.scss";
import Image from "next/image";
import { FC} from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { staticResource } from "@/lib/utils/resources";
import { H3, P2, P3 } from "../../Typography";
import cl from "classnames";
import { Button, CircleButton } from "../../Buttons";
import { ArrowIcon } from "@/lib/ui/icons";
import { useWindowSize } from "@/lib/hooks/useWindowSize";
import { useAppSelector } from "@/lib/store/app";
import { toMoney } from "@/lib/utils/toMoney";
import { getDate } from "@/lib/utils/getDate";
import { getWordEnding } from "@/lib/utils/getWordEnding";
import { compareDates } from "@/lib/utils/compareDates";

type SelectionProps = {
  layout: SelectionLayout;
};

export const SelectionCardPdf: FC<SelectionProps> = ({ layout }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("index");
  const { isMobile } = useWindowSize(1200);
  const { symbol: cur, value } = useAppSelector((state) => state.settings.currency);
  const money = layout.price && toMoney(layout.price[value]);
  const districtsString = layout.districts
    .map((item) => t(`locations.${item}`))
    .join(", ");
  const location = `${t(`locations.${layout.region}`)}, ${districtsString}`;
  const incomeText = layout.projectedIncome
    ? t("projectedIncome")
    : layout.rentalPool && !layout.guaranteedIncome
    ? t("rentalPool") + ":"
    : t("guaranteedIncome");
  const incomeInfo = layout.projectedIncome
    ? `${layout.projectedIncomePercent}% ${t("declinable.projectedPerYear")}`
    : layout.rentalPool && !layout.guaranteedIncome && layout.rentalPoolPercent
    ? `${layout.rentalPoolPercent}/${100 - layout.rentalPoolPercent}%`
    : `${layout.guaranteedIncomePercent}% ${t("declinable.perYear")} ${
        layout.guaranteedIncomePeriod
      } ${
        layout.guaranteedIncomePeriod &&
        t(
          getWordEnding(
            layout.guaranteedIncomePeriod,
            ["declinable.projectedPerYear", "declinable.years", "declinable.yearsFew"],
            language as "ru" | "en",
          ),
        )
      }`;

  const currentDate = new Date().toISOString();
  const constructionState = layout.constructionState.isReady
    ? `${t("readyForMoveIn")}`
    : layout.constructionState.offPlan
    ? `${t("offPlan")}`
    : layout.constructionState.constructionDate &&
      compareDates(layout.constructionState.constructionDate, currentDate)
    ? `${t("constructionDate")}: ${getDate(layout.constructionState.constructionDate)}`
    : "";

  const { beach, minutes, method } = layout.distanceToBeach;

  const beachText = `${t(`locations.${beach}`)}, ${minutes} ${`${getWordEnding(
    minutes,
    [t("declinable.minute"), t("declinable.minutes"), t("declinable.minutesMore")],
    language as "ru" | "en",
  )} ${method === "FOOT" ? t("byFoot") : t("byCar")}`}`;

  const url = `/${language}/sale/${layout.region === "PHUKET" ? "phuket" : "samui"}/${
    layout.slug
  }?layout=${layout.id}`;

  const views = layout.views;
  if (views.includes("OTHER")) {
    views.splice(views.indexOf("OTHER"), 1);
    views.push("OTHER");
  }



  return (
    <li className={cl(styles.card, styles.pdf)}>
      <div className={styles.gallery}>
        <div className={styles.gallery_top}>
          {layout.perfectForInvestment && (
            <P2 className={styles.gallery_top_button}>{t("idealForInvestment")}</P2>
          )}
          {layout.exclusive && (
            <P2 className={styles.gallery_top_button}>{t("exclusive")}</P2>
          )}
        </div>

        <div className={styles.gallery_wrapper}>
          <div className={styles.gallery_slider}>
            <Image
              sizes="1800px"
              className={styles.pdf_image}
              src={staticResource(layout.images[0]) || "/images/placeholder_image.webp"}
              style={{objectFit: 'cover', objectPosition: 'center'}}
              width={437}
              height={385}
              loading="eager"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footer_top}>
          <div>
            <H3 className={styles.cardTitle}>{layout.complexName}</H3>
            <H3 className={styles.cardTitle}>
              {t(layout.type)} {layout.name}
            </H3>
          </div>
          <P2 className={styles.construction}>{constructionState}</P2>
        </div>
        <div className={styles.footer_info}>
          <div className={styles.footer_block}>
            <div className={styles.footer_info_item}>
              <P3>{t("location")}:</P3>
              <P2>{location}</P2>
            </div>
            {beachText && (
              <div className={styles.footer_info_item}>
                <P3>{t("beach")}:</P3>
                <P2>{beachText}</P2>
              </div>
            )}

            <div className={styles.footer_info_item}>
              <P3>{incomeText}:</P3>
              <P2>{incomeInfo}</P2>
            </div>
          </div>
          <div className={styles.footer_block}>
            <div className={styles.footer_info_item}>
              <P3>{t("view")}:</P3>
              <P2>{layout.views.map((item) => t(item)).join(", ")}</P2>
            </div>
            <div className={styles.footer_info_item}>
              <P3>{t("totalArea")}:</P3>
              <P2>
                {layout.area} {t("prepositions.m2")}
              </P2>
            </div>
            <div className={styles.footer_info_item}>
              <P3>{t("bedrooms")}:</P3>
              <P2>{layout.bedrooms === 0 ? t("studio") : layout.bedrooms}</P2>
            </div>
          </div>
        </div>
        {layout.comment && (
          <div className={styles.commentWrapper}>
            <P3 className={cl(styles.commentText, styles['commentText--pdf'])}>{layout.comment}</P3>
          </div>
        )}
        <div className={styles.bottom}>
          {isMobile ? (
            <CircleButton
              as={Link}
              href={url}
              content={<ArrowIcon isRight />}
              theme={"mixed"}
              target="_blank"
              scroll
            />
          ) : (
            <Button
              as={Link}
              href={url}
              target="_blank"
              label={t("moreAbout")}
              theme={"dark"}
              size={"md"}
              scroll
            />
          )}
          {money && cur && (
            <H3 className={styles.price}>
              {cur}
              {money}
            </H3>
          )}
        </div>
      </div>
    </li>
  );
};
