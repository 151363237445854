import React from "react";
import { staticResource } from "@villa/utils/resources";
import Link from "next/link";
import Image from "next/image";
import styles from "../Card.module.scss";
import cl from "classnames";
import { sendSocialClick } from "@/lib/utils/sendGTM";

interface IProps {
  image: any;
  path: string;
}

export const InstagramCard = ({ image, path }: IProps) => {
  return (
    <Link
      aria-label="instagram post"
      target={"_blank"}
      href={`https://www.instagram.com/p/${path}`}
      onClick={sendSocialClick}
    >
      <div className={cl(styles.card, styles["card--insta"])}>
        <Image
          sizes="(max-width: 1624px): 255px, (max-width: 996px) 211px, 211px"
          src={staticResource(image)}
          alt={""}
          fill
        />
      </div>
    </Link>
  );
};

export default InstagramCard;
