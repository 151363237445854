import React from "react";
import Image from "next/image";
import styles from "./ReviewCard.module.scss";
import { H3, P3, P4 } from "../../Typography";

interface IProps {
  name: string;
  profession: string;
  avatar: string;
  text: string;
  onClick: () => void;
}

export const ReviewCard = ({ name, profession, avatar, text, onClick }: IProps) => {
  return (
    <div className={styles.review} onClick={onClick}>
      <div className={styles.review__top}>
        <Image
          src={avatar}
          alt={name}
          width={88}
          height={88}
          sizes="(max-width: 1440px) 58px, 88px"
        />
        <div className={styles.review__inner}>
          <H3>{name}</H3>
          <P4>{profession}</P4>
        </div>
      </div>

      <div className={styles.review__divider} />

      <P3>{text}</P3>
    </div>
  );
};

export default ReviewCard;
